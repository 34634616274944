import { makeStyles } from '@fluentui/react-components';

import { Image } from '@fluentui/react-components';
import { useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';

const useClasses = makeStyles({
    logo: {
        width: '110px',
        marginLeft: '20px',
    },
});

export const BrandLogo: React.FC = () => {
    const classes = useClasses();
    const { tenantSettings } = useAppSelector((state: RootState) => state.app);

    return (
        <div>
            <Image src={tenantSettings.logo} className={classes.logo} />
        </div>
    );
};
