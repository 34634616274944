import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { BackendServiceUrl } from './BaseService';
import { ServiceInfo } from '../models/ServiceInfo';

const reactPlugin: ReactPlugin = new ReactPlugin();
let appInsights: ApplicationInsights | null;
let cn = 'instrumentationKey=test';
// Fetch the connection string from the backend
void fetch(new URL('telemetry', BackendServiceUrl))
.then((response) => (response.ok ? (response.json() as Promise<ServiceInfo>) : Promise.reject())
.then((serviceInfo) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
        cn = serviceInfo.appInsightsConnectionString;
        appInsights = new ApplicationInsights({
            config: {
                connectionString: cn,
                extensions: [reactPlugin],
                extensionConfig: {},
                enableAutoRouteTracking: true,
                disableAjaxTracking: false,
                autoTrackPageVisitTime: true,
                enableCorsCorrelation: true,
                enableRequestHeaderTracking: true,
                enableResponseHeaderTracking: true,
            },
        });

        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        appInsights.loadAppInsights();

        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        appInsights.addTelemetryInitializer((env: ITelemetryItem) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            env.tags ??= [];
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            env.tags['ai.cloud.role'] = 'copilot-react';
        });

        
    }));

export { reactPlugin, appInsights };
