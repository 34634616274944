import { makeStyles } from '@fluentui/react-components';
import Logo from '../../assets/endlessideas-logo-dark.svg';

const useClasses = makeStyles({
    logo: {
        width: '110px',
        marginLeft: '20px',
    },
});

export const StarterLogo: React.FC = () => {
    const classes = useClasses();

    return (
        <div>
            <img src={Logo} className={classes.logo} />
        </div>
    );
};
