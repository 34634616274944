import React from 'react';

// If you're putting this in the same file
declare global {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    namespace JSX {
        interface IntrinsicElements {
            'end-widget-indice': any;
        }
    }
}

class WidgetViewer extends React.Component {
    componentDidMount() {
        const url = 'https://cdn.staging.simplifinance.com.br/public/widgets/widget-indice.js';

        // Check if script is already appended
        if (document.querySelector(`script[src="${url}"]`)) {
            return;
        }

        const script = document.createElement('script');
        script.src = url;
        script.async = true;
        document.body.appendChild(script);
    }

    render() {
        return (
            <div>
                <end-widget-indice sandbox indice="1" theme="light"></end-widget-indice>
            </div>
        );
    }
}

export default WidgetViewer;
